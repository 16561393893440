import { InjectionKey, Ref } from 'vue';

export type AssessmentFinished = {
  currAssessmentFinished: Ref<boolean>;
  updateAssessmentFinished: (value: boolean) => void;
};

export type AssessmentCreated = {
  newAssessmentCreated: Ref<boolean>;
  updateNewAssessmentCreated: (value: boolean) => void;
};

export type ToggleEdit = {
  toggleEditModal: Ref<boolean>;
  updateToggleEditModal: (value: boolean) => void;
};

export const assessmentFinishedKey: InjectionKey<AssessmentFinished> = Symbol('assessmentFinished');
export const assessmentCreatedKey: InjectionKey<AssessmentCreated> = Symbol('newAssessmentCreated');
export const toggleEditModalKey: InjectionKey<ToggleEdit> = Symbol('toggleEditModal');